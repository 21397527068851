/** @format */
import axios from 'axios';

/**
 * Log a post interaction (e.g. ad click)
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const logInteraction = function (data) {
    return axios
        .post('/post/interaction/create', {
            data
        })
        .then((response) => response.data);
};

/**
 * Log a general interaction (not tied to a post)
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const logGeneralInteraction = function (data) {
    return axios
        .post('/interaction/create', data)
        .then((response) => response.data);
};


<!-- @format -->
<template>
    <div class="content">
        <p class="is-size-5 has-text-centered has-text-weight-bold mb-0">
            Thank you so much for reading <span style="white-space: nowrap">{{ publication.name }}</span
            >.
        </p>
        <p v-if="isEndTrial" class="is-size-5 has-text-centered">
            We hope you have enjoyed your free trial. To read more, you can
        </p>
        <p v-else class="is-size-5 has-text-centered">To read more, you can</p>

        <div class="columns">
            <div class="column has-text-centered na-add-credit-box">
                <p v-if="isEndTrial">
                    Purchase a monthly or yearly <span class="has-text-weight-bold">subscription</span>, giving you
                    complete access to all {{ publication.name }} articles.
                </p>
                <p v-else>
                    Purchase a <span class="has-text-weight-bold">subscription</span>, giving you complete access to all
                    {{ publication.name }} articles.
                </p>
                <button type="button" class="button is-success is-uppercase" @click="$emit('subscribe-click')">Subscribe</button>
            </div>
            <div class="column has-text-centered na-add-credit-box">
                <template v-if="isEndTrial">
                    <p>
                        Purchase <span class="has-text-weight-bold">just this article</span> by using NewsAtomic's
                        EZRead system.
                        <br />
                        <span
                            class="has-text-success is-uppercase is-clickable"
                            @click="$emit('ez-read-description-click')"
                            >What's this?</span
                        >
                    </p>
                </template>
                <p v-else>
                    Purchase <span class="has-text-weight-bold">just this article</span>.<br />
                    Replenish your EZRead balance.
                </p>
                <button  type="button" class="button is-success" @click="$emit('ez-read-click')">EZRead</button>
            </div>
        </div>

        <p class="is-size-5 has-text-centered is-italic mb-0">
            Local, independent journalism depends on you, our readers.
        </p>
        <p class="is-size-5 has-text-centered is-italic">Thank you for your support!</p>
    </div>
</template>

<script>
export default {
    props: {
        isEndTrial: {
            type: Boolean,
            required: true
        },
        publication: {
            type: Object,
            required: true
        }
    }
};
</script>

/** @format */

import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import PostViewer from '../views/PostViewer.vue';

// Account Pages
const Account = () =>
    import(/*webpackChunkName: "acount" */ '../views/Account.vue');
const CartoonHelpWanted = () =>
    import(/* webpackChunkName: "other-pages" */ '../views/HelpWanted.vue');
const DeleteAccount = () =>
    import(/*webpackChunkName: "acount" */ '../views/DeleteAccount.vue');
const KwSubscribe = () =>
    import(/*webpackChunkName: "acount" */ '../views/KwSubscribe.vue');
const MakeContribution = () =>
    import(/*webpackChunkName: "sj" */ '../views/SJ/MakeContribution.vue');
const MiSubscribe = () =>
    import(/*webpackChunkName: "acount" */ '../views/MiSubscribe.vue');
const PrintSubscriptionRenewal = () =>
    import(/*webpackChunkName: "sj" */ '../views/SJ/PrintSubscriptionRenewal.vue');
const SjSubscribe = () =>
    import(/*webpackChunkName: "acount" */ '../views/SjSubscribe.vue');
const Subscribe = () =>
    import(/*webpackChunkName: "acount" */ '../views/Subscribe.vue');

// Misc pages
const About = () =>
    import(
        /* webpackChunkName: "other-pages" */ '../components/Content/Corporate/About/Index.vue'
    );
const FAQ = () =>
    import(
        /* webpackChunkName: "other-pages" */ '../components/Content/Corporate/Faq/Index.vue'
    );
const Contact = () =>
    import(
        /* webpackChunkName: "other-pages" */ '../components/Content/Corporate/Contact/Index.vue'
    );
const Terms = () =>
    import(
        /* webpackChunkName: "other-pages" */ '../components/Content/Corporate/Terms/Index.vue'
    );
const DataRetentionPolicy = () =>
    import(
        /* webpackChunkName: "other-pages" */ '../views/DataRetentionPolicy.vue'
    );
const Ad = () =>
    import(
        /* webpackChunkName: "other-pages" */ '../components/Content/Corporate/Ad/Index.vue'
    );
const Tip = () => import(/* webpackChunkName: "other-pages" */ '../views/Tip.vue');
const PublicationHome = () =>
    import(/* webpackChunkName: "other-pages" */ '../views/PublicationHome.vue');

// Authentication Pages
const Login = () => import(/* webpackChunkName: "auth" */ '../views/Login.vue');
const LoginWithCode = () => import(/* webpackChunkName: "auth" */ '../views/LoginWithCode.vue');
const Register = () =>
    import(/* webpackChunkName: "auth" */ '../views/Register.vue');
const RegisterConfirm = () =>
    import(/* webpackChunkName: "auth" */ '../views/RegisterConfirm.vue');
const RegisterConfirmRequest = () =>
    import(/* webpackChunkName: "auth" */ '../views/RegisterConfirmRequest.vue');
const Landing = () =>
    import(/* webpackChunkName: "auth" */ '../views/Landing/FreeTrial.vue');

const ResetPassword = () =>
    import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue');
const ForgotPassword = () =>
    import(/* webpackChunkName: "reset-password" */ '../views/ForgotPassword.vue');

// Gift Subscription Pages
const GiftSubscriptionStart = () =>
    import(
        /* webpackChunkName: "gift-subscription" */ '../views/GiftSubscription/Start.vue'
    );
const GiftSubscription = () =>
    import(
        /* webpackChunkName: "gift-subscription" */ '../views/GiftSubscription/Index.vue'
    );
const GiftSubscriptionActivate = () =>
    import(
        /* webpackChunkName: "gift-subscription-activate" */ '../views/GiftSubscription/Activate.vue'
    );
const ArchiveLinkSelector = () =>
    import(
        /* webpackChunkName: "archive-link-selector" */ '../views/ArchiveLink/Selector.vue'
    );
const HvNews = () =>
    import(
        /*webpackChunkName: "hvnews" */ '../views/HvNews/Index.vue'
    );
const KWSpecial = () =>
    import(
        /*webpackChunkName: "kwspecial" */ '../views/Landing/KWSpecial.vue'
    );
const KWSpecialFinish = () =>
    import(
        /*webpackChunkName: "kwspecial-finish" */ '../views/Landing/KWSpecialFinish.vue'
    );
const NotFound = () =>
    import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue');

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: {
            view: 'home'
        }
    },
    {
        name: 'hvnews',
        path: '/hvnews/:publicationIds',
        component: HvNews,
        meta: {
            view: 'hvnews'
        }
    },
    {
        name: 'post-viewer-section',
        path: '/section/:sectionId',
        component: PostViewer,
        meta: {
            view: 'post-viewer'
        }
    },
    {
        name: 'post-viewer-whats-new',
        path: '/whats-new',
        component: PostViewer,
        meta: {
            view: 'post-viewer'
        }
    },
    {
        name: 'post-viewer-short',
        path: '/post/link/:identifier',
        component: PostViewer,
        meta: {
            view: 'post-viewer'
        }
    },
    {
        path: '/post/link/open/:identifier',
        redirect: (to) => {
            return {
                name: 'post-viewer-short',
                params: {
                    ...to.params
                },
                query: {
                    ...to.query,
                    open: 1
                }
            };
        }
    },
    {
        path: '/post/link/purchase/:identifier',
        redirect: (to) => {
            return {
                name: 'post-viewer-short',
                params: {
                    ...to.params
                },
                query: {
                    ...to.query,
                    open: 1
                }
            };
        }
    },
    {
        // @todo Check me - throwing a warning in the console about titleSlug
        name: 'post-viewer',
        path: '/:sectionRoute/:year/:month/:day/:titleSlug/:identifier',
        component: PostViewer,
        meta: {
            view: 'post-viewer'
        }
    },
    {
        path: '/:sectionRoute/:year/:month/:day/:titleSlug/open/:identifier',
        redirect: (to) => {
            return {
                name: 'post-viewer',
                params: {
                    ...to.params
                },
                query: {
                    ...to.query,
                    open: 1
                }
            };
        }
    },
    {
        name: 'post-viewer-old',
        path: '/:postType/:postCategory/:year/:month/:day/:titleSlug/:identifier',
        component: PostViewer,
        meta: {
            view: 'post-viewer'
        }
    },
    {
        path: '/:postType/:postCategory/:year/:month/:day/:titleSlug/open/:identifier',
        redirect: (to) => {
            return {
                name: 'post-viewer',
                params: {
                    ...to.params
                },
                query: {
                    ...to.query,
                    open: 1
                }
            };
        }
    },
    {
        name: 'about',
        path: '/about-us',
        alias: ['/about.html', '/staff.html', '/resources.html'],
        component: About,
        meta: {
            view: 'corporate'
        }
    },
    {
        name: 'faq',
        path: '/faq',
        component: FAQ,
        meta: {
            view: 'corporate'
        }
    },
    {
        name: 'contact',
        path: '/contact',
        alias: '/contact.html',
        component: Contact,
        meta: {
            view: 'corporate'
        }
    },
    {
        name: 'tip',
        path: '/tip',
        component: Tip,
        meta: {
            view: 'corporate'
        }
    },
    {
        name: 'cartoon',
        path: '/cartoon',
        component: CartoonHelpWanted,
        meta: {
            view: 'corporate'
        }
    },
    {
        name: 'terms',
        path: '/corporate/terms_and_conditions',
        component: Terms,
        meta: {
            view: 'corporate'
        }
    },
    {
        name: 'data-retention-policy',
        path: '/data-retention-policy',
        component: DataRetentionPolicy,
        meta: {
            view: 'corporate'
        }
    },
    {
        name: 'ad',
        path: '/advertising',
        component: Ad,
        meta: {
            view: 'corporate'
        }
    },
    {
        name: 'reset-password',
        path: '/reset-password/:email/:token',
        component: ResetPassword
    },
    {
        name: 'forgot-password',
        path: '/forgot-password',
        component: ForgotPassword
    },
    {
        name: 'register',
        path: '/register',
        component: Register
    },
    {
        name: 'register-confirm',
        path: '/register/confirm/:email/:token',
        component: RegisterConfirm
    },
    {
        name: 'register-confirm-request',
        path: '/register/request/:email',
        component: RegisterConfirmRequest
    },
    {
        name: 'login',
        path: '/login',
        component: Login,

    },
    {
        name: 'login-with-code',
        path: '/login-with-code',
        component: LoginWithCode,
    },
    {
        name: 'account',
        path: '/account',
        component: Account,
        meta: {
            requireAuth: true
        }
    },
    {
        name: 'delete-account',
        path: '/delete-account',
        component: DeleteAccount,
        meta: {
            requireAuth: true
        }
    },
    {
        name: 'publication-terms',
        path: '/publication/terms_and_conditions/:id',
        component: PublicationHome
    },
    {
        name: 'publication-privacy',
        path: '/publication/privacy_policy/:id',
        component: PublicationHome
    },
    {
        name: 'subscribe',
        path: '/subscribe',
        component: Subscribe
    },
    {
        name: 'sjsubscribe',
        path: '/sjsubscribe',
        component: SjSubscribe
    },
    {
        name: 'kwsubscribe',
        path: '/kwsubscribe',
        component: KwSubscribe
    },
    {
        name: 'misubscribe',
        path: '/misubscribe',
        component: MiSubscribe
    },
    {
        name: 'gift-subscription-start',
        path: '/subscribe/gift',
        alias: '/giftsubs',
        component: GiftSubscriptionStart,
        meta: {
            requireAuth: false
        }
    },
    {
        name: 'gift-subscription',
        path: '/subscribe/gift/:slug',
        component: GiftSubscription,
        meta: {
            requireAuth: false
        }
    },
    {
        name: 'gift-subscription-activate',
        path: '/activate',
        component: GiftSubscriptionActivate
    },

    {
        name: 'sj-print-subscription-renewal',
        path: '/sj-renew',
        component: PrintSubscriptionRenewal
    },

    {
        name: 'sj-make-contribution',
        path: '/contribute',
        component: MakeContribution,
        props: {
            publication_slug: 'shawangunk-journal'
        }
    },

    {
        name: 'kw-make-contribution',
        path: '/donate',
        component: MakeContribution,
        props: {
            publication_slug: 'kingston-wire'
        }
    },

    {
        name: 'landing',
        path: '/freetrial/:ref?',
        component: Landing,
        meta: {
            unauthOnly: true,
            genericAppEnabled: true
            //kwAppEnabled: true
        }
    },

    {
        name: 'kwspecial',
        path: '/special',
        component: KWSpecial,
        meta: {
            kwAppEnabled: true,  // Allows auth as well as unauth
        }
    },

    {
        name: 'kwspecial-finish',
        path: '/special/complete/:activationToken',
        component: KWSpecialFinish,
        meta: {
            kwAppEnabled: true
        }
    },

    {
        name: 'search',
        path: '/search',
        component: Home,
        meta: {
            view: 'home'
        }
    },

    {
        name: 'section',
        path: '/:sectionRoute',
        component: Home,
        meta: {
            view: 'home'
        }
    },

    {
        name: 'archive-link-selector',
        path: '/archive/selector',
        component: ArchiveLinkSelector
    },

    {
        name: 'not-found',
        path: '*',
        component: NotFound,
        beforeEnter(to, from, next) {
            document.head.children.robots?.setAttribute('content', 'none');
            next();
        },
        meta: {
            view: 'not-found'
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    duplicateNavigationPolicy: 'ignore',
    linkActiveClass: 'is-active',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    // Special case - if coming from hvnews page, don't redirect and don't need auth
    if (from && from.name === 'hvnews' && to && to.name === 'post-viewer') {
        next();
    } else {
        if (window.userAuthenticated && to.meta.unauthOnly) {
            next({ name: 'home' });
        }

        const authGuard = to.meta.requireAuth && !window.userAuthenticated;

        const genericAppEnabled = to.meta.genericAppEnabled ?? true;
        const kwAppEnabled = to.meta.kwAppEnabled ?? true;
        let appGuard = false;
        if (!window.mainPublicationSlug) {
            appGuard = !genericAppEnabled;
        } else if (window.mainPublicationSlug === 'kingston-wire') {
            appGuard = !kwAppEnabled;
        }

        if (authGuard || appGuard) {
            next({
                name: 'not-found',
                params: {
                    pathMatch: to.path
                },
                query: to.query
            });
        } else {
            next();
        }
    }
});

export default router;
